/* eslint-disable */

import { GenericModel } from '@/shared/model/generic-model'
import { i18n } from '@/locales/i18n'
import IdField from '@/shared/fields/id-field'
import StringField from '@/shared/fields/string-field'
import JsonField from '@/shared/fields/json-field'

function label(name) {
  return i18n(`vehicle.fields.${name}`)
}

const fields = {
  id: new IdField('id', label('id')),
  image: new StringField('image', label('image')),
  name: new JsonField('name', label('name'), ['en', 'ar'])
}

export class VehicleModel extends GenericModel {
  static get fields() {
    return fields
  }
}
