<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <!-- <app-i18n code="vehicle.edit.title" v-if="isEditing"></app-i18n> -->
            <!-- <app-i18n code="vehicle.new.title" v-else></app-i18n> -->
            <app-i18n code="vehicle.new.title"></app-i18n>
          </h2>
          <section class="relative">
            <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="findLoading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>
            <div v-if="model">
              <div class="grid grid-cols-12 mt-5">
                <div class="lg:col-span-4 col-span-12">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.name.en"
                    :placeholder="i18n('vehicle.edit.nameInEnglish')"
                  />
                </div>
              </div>
              <div class="grid grid-cols-12 mt-5">
                <div class="lg:col-span-4 col-span-12">
                  <input
                    id="crud-form-1"
                    type="text"
                    class="form-control w-full dark:bg-dark-9"
                    v-model="model.name.ar"
                    :placeholder="i18n('vehicle.edit.nameInArabic')"
                  />
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="
                    btn
                    bg-theme-31
                    text-black
                    w-24
                    ml-3
                    mr-3
                    cursor-pointer
                  "
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'
import { FormSchema } from '@/shared/form/form-schema'
import { VehicleModel } from '@/store/vehicle/vehicle-model'

const { fields } = VehicleModel
const formSchema = new FormSchema([fields.id, fields.name])

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false
    }
  },
  setup() {
    const errorMessage = ref('')

    return {
      errorMessage
    }
  },
  data() {
    return {
      model: null,
      rules: formSchema.rules()
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'vehicle/form/record',
      findLoading: 'vehicle/form/findLoading',
      saveLoading: 'vehicle/form/saveLoading'
    }),
    isEditing() {
      return !!this.id
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.vehicles')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.vehicles')
    }
  },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id)
    } else {
      this.doNew()
    }
    this.model = formSchema.initialValues(this.record || {})
  },
  methods: {
    ...mapActions({
      doNew: 'vehicle/form/doNew',
      doFind: 'vehicle/form/doFind',
      doCreate: 'vehicle/form/doCreate'
      // doUpdate: 'vehicle/form/doUpdate'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (
        this.model.name.en.trim().length == 0 ||
        this.model.name.ar.trim().length == 0
      ) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    doReset() {
      this.model = formSchema.initialValues(this.record)
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { id, name } = formSchema.cast(this.model)
      if (this.isEditing) {
        await this.doUpdate({
          id,
          name
        })
      } else {
        await this.doCreate(name)
      }
    }
  }
})
</script>
